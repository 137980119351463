<template>
   <footer id="contact" class="footer">
      <div class="bubbles"></div>
      <div class="footer-panel">
         <div class="hire">
            <h2>Reach out to me<span class="smallDot"></span></h2>
            <span class="hire-me"
               ><i class="bi bi-laptop"></i><a href="mailto:kiwwwano@gmail.com">Hire me<i class="bi bi-arrow-left"></i></a
            ></span>
         </div>
         <div class="quick-links">
            <h2>Links<span class="smallDot"></span></h2>
            <ul>
               <li><a href="https://parkinghere.kiwwwano.com">parkinghere</a></li>
               <li><a href="https://ticketmarket.kiwwwano.com">Ticket Market</a></li>
            </ul>
         </div>
         <div class="contact-section">
            <h2>Contact<span class="smallDot"></span></h2>
            <ul>
               <li>
                  <span><i class="bi bi-envelope-fill"></i></span
                  ><span><a href="mailto:kiwwwano@gmail.com">kiwwwano@gmail.com</a></span>
               </li>
            </ul>
         </div>
      </div>
      <p>© Portfolio kiwwwano 2022</p>
   </footer>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
   name: "FooterComp",
   mounted: function () {
      this.scrollAnimation();
      if (screen.width > 1300) {
         this.bubbles();
      }
   },
   methods: {
      bubbles() {
         let x = 10;
         let i = 0;

         while (i < x) {
            let bubbles = document.querySelector(".bubbles");
            let rand = Math.random();

            let put = document.createElement("i");
            put.classList.add("bubble");
            let size = rand * 5;
            let pos = Math.floor(Math.random() * window.innerWidth);
            let delay = Math.random() * -10;
            let duration = rand * 40;

            put.style.width = rand * 150 + size + "px";
            put.style.height = rand * 150 + size + "px";
            put.style.marginLeft = pos + "px";
            put.style.animationDelay = delay + "s";
            put.style.animationDuration = duration + "s";

            bubbles.appendChild(put);
            i++;
         }
      },
      scrollAnimation() {
         const gs = gsap.timeline({
            scrollTrigger: {
               trigger: ".footer",
               start: "-70% bottom",
            },
         });
         gs.fromTo(
            ".footer",
            { y: "20rem", opacity: 0 },
            {
               y: 0,
               opacity: 1,
               ease: "back.out(1)",
               duration: 1.0,
            }
         );
         return () => {
            gs.scrollTrigger.kill();
         };
      },
   },
};
</script>

<style>
.footer {
   display: flex;
   justify-content: center;
   width: 100%;
   overflow: hidden;
   font-size: 20px;
   color: #dadada;
   text-transform: uppercase;
   background-color: #131313;
   border-top: 1px solid #282828;
}

.footer h2 {
   font-size: 40px;
}

.footer a {
   color: #dadada;
}

.footer-panel {
   display: flex;
   justify-content: center;
   width: 100%;
   margin: 120px 0;
}

.footer-panel div {
   margin: 0 60px;
}

.footer-panel i {
   margin-right: 10px;
}

.footer ul {
   list-style: none;
}

.footer p {
   position: absolute;
   left: 50%;
   bottom: 30px;
   transform: translateX(-50%);
   font-size: 19px;
   margin: 0;
}

.contact-section h2,
.quick-links h2 {
   position: relative;
   margin-bottom: 15px;
}

.hire h2 {
   position: relative;
   margin-bottom: 20px;
}

.hire h2::before,
.contact-section h2::before,
.quick-links h2::before {
   content: "";
   position: absolute;
   top: 100%;
   left: 50%;
   transform: translate(-50%, 7px);
   width: 60%;
   border-bottom: 1px solid #282828;
}

.hire-me {
   padding: 0 50px 0 10px;
   background-color: #fd5e5e;
}

span.hire-me {
   color: black;
   font-size: 26px;
}

span.hire-me:hover {
   background-color: rgb(255, 204, 73);
}

.bi-envelope-fill {
   color: #fd5e5e;
}

.contact-section li:hover .bi-envelope-fill {
   color: #ffcc49;
}

.contact-section li a {
   font-size: 28px;
}

.contact-section li:hover a {
   color: #fd5e5e;
}

.quick-links li {
   margin: 5px 0;
   border-left: 7px solid #fd5e5e;
   font-size: 28px;
}

.quick-links li:hover {
   margin: 5px 0;
   border-left: 7px solid #ffcc49;
}

.quick-links li:hover a {
   color: #fd5e5e;
}

.hire-me a {
   color: black;
   font-weight: bold;
}

.bubbles {
   opacity: 1;
}

.bubbles i {
   position: absolute;
   height: 150px;
   width: 150px;
   border-radius: 90px;
   background-color: white;
   animation: bubbles 10s alternate infinite;
}

.bubbles i:nth-child(3n + 1) {
   background-color: rgb(17, 17, 17);
   z-index: -1;
}

.bubbles i:nth-child(3n + 2) {
   background-color: rgb(21, 21, 21);
   z-index: 1;
}

.bubbles i:nth-child(3n + 3) {
   background-color: rgb(15, 15, 15);
}

.bubbles i:nth-child(3n + 4) {
   background-color: rgb(14, 14, 14);
}

.bubbles i:hover {
   background-color: rgba(255, 255, 255, 0.15);
   border: 2px solid rgba(255, 82, 82, 0.35);
}

footer .smallDot {
   z-index: -1;
}

.bi-arrow-left {
   position: absolute;
   margin-left: 12px;
   animation: totheleft 0.7s alternate infinite;
}
@keyframes totheleft {
   0% {
      transform: translatex(5px);
   }
   100% {
      transform: translatex(-5px);
   }
}
@media (max-width: 1100px) and (orientation: portrait) {
   .footer-panel {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      margin: 0 30px 50px 30px;
   }

   .footer-panel div {
      margin: 20px 0;
   }

   .footer p {
      bottom: 10px;
      font-size: 3vw;
   }

   .hire {
      width: 100%;
   }

   .smallDot {
      height: 7px;
      width: 7px;
   }

   .quick-links a,
   .contact-section ul li a {
      margin: 5px 0;
      font-size: 5.5vw;
   }

   span.hire-me,
   .hire-me i {
      font-size: 5vw;
   }
   .hire-me i {
      line-height: 35px;
   }

   .hire h2,
   .quick-links h2,
   .contact-section h2 {
      font-size: 6.5vw;
   }

   .bubbles {
      display: none;
   }
}
@media (max-width: 1200px) and (orientation: landscape) {
   .footer {
      height: 650px;
      margin: 0;
   }

   .footer-panel {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      margin: 0 30px 50px 30px;
   }

   .footer-panel div {
      margin-top: 40px;
   }

   .footer p {
      bottom: 10px;
      font-size: 3vw;
   }

   .footer h2 {
      font-size: 5vw;
   }

   .hire {
      width: 100%;
   }

   .hire h2 {
      font-size: 5vw;
   }

   .smallDot {
      height: 7px;
      width: 7px;
   }

   .hire-me,
   .quick-links,
   .contact-section {
      font-size: 5vw;
   }

   .bubbles {
      display: none;
   }
}
@media (max-width: 1366px) and (orientation: landscape) {
   .footer-panel div {
      margin: 0 25px;
   }
}
@media (max-width: 330px) and (orientation: portrait) {
   .hire h2 {
      font-size: 5.5vw;
   }
   .hire-me i {
      font-size: 5vw;
   }
   .hire-me i {
      display: none;
   }
}
</style>
