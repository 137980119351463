<template>
  <div id="app" class="container-fluid p-0 position-relative">
    <div
      class="menu-burger"
      :class="{ active: isActive }"
      @click="isActive = !isActive"
    >
      <div class="burger"></div>
    </div>
    <IntroComp />
    <SayHi />
    <ParallaxComp class="overflow-hidden" />
    <NavHeader :class="{ active: isActive }" />
    <AboutComp />
    <StrenghtsComp />
    <TechComp />
    <ProjectsComp />
    <FooterComp />
    <a href="#top"
      ><div class="scroll-up" :class="{ sticky: scrollPosition > 950 }">
        <i class="bi bi-caret-up-fill"></i></div
    ></a>
  </div>
</template>

<script>
import ParallaxComp from "./components/ParallaxComp.vue";
import NavHeader from "./components/NavHeader.vue";
import AboutComp from "./components/AboutComp.vue";
import StrenghtsComp from "./components/StrenghtsComp.vue";
import TechComp from "./components/TechComp.vue";
import ProjectsComp from "./components/ProjectsComp.vue";
import FooterComp from "./components/FooterComp.vue";
import IntroComp from "./components/IntroComp.vue";
import SayHi from "./components/SayHi.vue";

export default {
  name: "App",
  components: {
    ParallaxComp,
    NavHeader,
    AboutComp,
    StrenghtsComp,
    TechComp,
    ProjectsComp,
    FooterComp,
    IntroComp,
    SayHi,
  },
  data() {
    return {
      isActive: false,
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Poller+One&family=Roboto&display=swap");
#app {
  overflow: hidden;
  position: relative;
}
* {
  padding: 0;
  margin: 0;
  font-family: "Roboto", "Josefin Sans", sans-serif;
  text-align: justify;
  box-sizing: border-box;
}

*::selection {
  color: #f39090;
}

* a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  background-color: black;
  max-width: 2600px;
  margin: 0 auto;
}
ul {
  padding-left: 0;
}
.menu-burger {
  z-index: 6;
}
.text {
  position: absolute;
  top: 133%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 4px;
  animation: color 0.2s linear forwards;
  animation-delay: 1.5s;
  color: white;
}

.sayHi {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  /*color: rgba(255, 255, 255, 0.1);*/
  font-size: 5vw;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 1;
  width: 300%;
  overflow: hidden;
  text-align: center;
  /*animation: glow 5.5s infinite;*/
  animation-delay: 8.5s;
}

.sayHi span {
  font-family: "Poller One", cursive;
  color: rgba(255, 203, 73, 0.2);
}

.scroll {
  position: absolute;
  bottom: -100px;
  left: 50%;
  color: #cbcbcb;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  animation: show-up 1s alternate forwards;
  animation-delay: 3.8s;
}

.scroll-about a {
  font-size: 22px;
  text-transform: uppercase;
  color: #dadada;
  z-index: 2;
  transition: 0.1s linear;
  text-decoration: none;
  font-weight: bold;
}

.scroll-about:hover a {
  letter-spacing: 2px;
}

.bi-caret-up-fill {
  color: #fd5e5e;
}

.scroll-about {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 15px;
  padding: 17px 15px;
  width: 190px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.scroll-about:hover::before {
  background-color: #fd5e5e;
}

.scroll-about::before {
  content: "";
  position: absolute;
  height: 45%;
  width: 270px;
  background-color: rgba(255, 255, 255, 0.8);
  animation: rotate alternate 4s infinite;
  filter: blur(2px);
  animation-delay: 6.5s;
}

.scroll-about::after {
  content: "";
  position: absolute;
  inset: 3px;
  background-color: #000000;
  border-radius: 15px;
}

section {
  margin: 0 auto;
  padding: 30px 15px 40px 15px;
  max-width: 1400px;
  overflow: hidden;
  border-top: 1px solid #191919;
}

section:last-child {
  margin: 0;
}

.section-name {
  margin-bottom: 10px;
  overflow: hidden;
  display: inline-flex;
  padding-right: 10px;
}

.section-name h2 {
  text-transform: uppercase;
  color: #e5e5e5;
  font-size: 70px;
  transform: translateY(30px);
  transition: 0.1s linear;
  margin: 0;
}

.section-name:hover h2 {
  transform: translateY(15px);
  animation: shiny-text 1s linear;
}

.section-name:hover .colorDot {
  transform: translate(8px, 3px);
}

section span {
  color: #e5e5e5;
  font-size: 24px;
}

.colorDot,
.smallDot,
.tinyDot {
  transform: translate(8px, -10px);
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: #fd5e5e;
  transition: 0.3s linear;
}

.smallDot {
  transform: translate(2px, 2px);
  height: 8px;
  width: 8px;
}

.tinyDot {
  transform: translate(2px, 1px);
  height: 4px;
  width: 4px;
  background-color: #e5e5e5;
}
.salmon {
  background-color: #fd5e5e;
  color: black;
}

.scroll-up {
  position: fixed;
  display: none;
  bottom: 10%;
  right: 7%;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  border: 2px solid rgba(255, 93, 93, 0.5);
  background-color: rgba(0, 0, 0, 0.57);
}

.scroll-up:hover {
  background-color: #ff5d5d;
}

.scroll-up.sticky {
  display: block;
}

.scroll-up i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #dadada;
  font-size: 24px;
}

.inprogress {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #171717;
  border-radius: 5px;
}

span.inprogress {
  font-size: 18px;
  color: #808080;
  text-transform: uppercase;
}

@keyframes arrowanimate {
  0% {
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes bubbles {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(300px);
  }
}

@keyframes border {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes shiny-text {
  0% {
    filter: blur(0px);
  }
  50% {
    filter: blur(4px);
  }
  100% {
    filter: blur(0px);
  }
}

@keyframes pulse {
  to {
    transform: scale(20);
    opacity: 0;
  }
}

@keyframes shiny {
  to {
    left: 100%;
  }
}

@keyframes rotate360 {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
    color: #ffde5f;
  }
}

@keyframes light {
  0% {
    height: 40px;
    left: 59.5%;
    text-shadow: none;
    opacity: 0.4;
    filter: blur(5px);
  }
  25% {
    height: 40px;
    /*left: 66.5%;*/
    text-shadow: 2px 2px 10px #ffcc49;
    opacity: 0.9;
    filter: blur(9px);
  }
  50% {
    height: 40px;
    text-shadow: 2px 2px 10px #ffcc49;
    opacity: 0.9;
    filter: blur(9px);
    left: 61.3%;
  }
  100% {
    height: 40px;
    text-shadow: none;
    opacity: 0;
    left: 59.5%;
  }
}

@keyframes show-up {
  to {
    bottom: 70px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  0% {
    text-shadow: none;
  }
  50% {
    text-shadow: 2px 2px 10px #ffcc49;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes disappear {
  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes border {
  to {
    box-shadow: 30px 50px 0 1px #fd5e5e;
  }
}

@keyframes color {
  to {
    color: #000000;
  }
}

@keyframes move-down {
  to {
    top: 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.6);
  }
  40% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(0.7);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 1100px) and (orientation: portrait) {
  img.mountain,
  img.forest,
  img.lake,
  img.sky {
    width: 300vw;
  }

  img.boat {
    display: none;
  }

  img.forest {
    top: 80%;
    left: 150%;
  }

  img.sky {
    bottom: -150px;
  }

  img.lake {
    bottom: -37%;
  }

  .menu-burger {
    right: 0;
  }

  .sayHi {
    display: flex;
    flex-wrap: wrap;
    top: 5%;
    font-size: 8vw;
    width: 100%;
    justify-content: center;
    display: none;
  }

  .sayHi span {
    text-align: center;
    color: rgba(255, 204, 73, 0.3);
    letter-spacing: 5px;
  }

  .section-name h2 {
    font-size: 9vw;
    transform: translateY(0px);
    width: 100%;
  }

  .section-name:hover h2 {
    transform: translateY(0px);
  }

  .section-name:hover .colorDot {
    transform: translate(2px, 1px);
  }

  section span {
    color: #e5e5e5;
    font-size: 30px;
  }

  .text-about-me span {
    font-size: 18px;
  }

  .code {
    width: 50%;
  }

  .bold {
    color: #b4b4b4;
  }

  .italic {
    font-style: italic;
  }

  .colorDot,
  .smallDot {
    transform: translate(2px, 1px);
    height: 10px;
    width: 10px;
  }

  .tinyDot {
    display: none;
  }

  .intro {
    display: flex;
    flex-direction: column;
  }

  #logo {
    width: 90vw;
    transform: translateY(-30px);
  }

  .logo {
    flex-direction: column;
  }

  #border {
    display: none;
  }

  .text {
    display: none;
  }

  .inprogress {
    margin-left: 15px;
    padding: 4px 6px;
    background-color: #171717;
    border-radius: 5px;
  }

  span.inprogress {
    font-size: 8px;
  }
  .scroll-up {
    bottom: 4%;
  }
  .scroll-up i {
    animation: none;
  }
  .overlay {
    animation: none;
  }

  @keyframes show-up {
    to {
      bottom: 120px;
    }
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  img.lake {
    top: 80%;
  }

  img.forest {
    top: 50%;
    left: 150%;
    width: 100vw;
  }

  img.boat {
    display: none;
  }

  img.mountain {
    top: 37%;
  }

  .menu-burger {
    right: 0;
  }

  .sayHi {
    display: flex;
    flex-wrap: wrap;
    font-size: 4vw;
    width: 100%;
    justify-content: center;
    display: none;
  }

  .sayHi span {
    text-align: center;
    color: rgba(255, 204, 73, 0.6);
  }

  .scroll-about {
    padding: 20px 15px;
    width: 150px;
  }

  .scroll p {
    font-size: 16px;
  }

  .scroll-about::before {
    content: "";
    position: absolute;
    height: 45%;
    width: 270px;
    background-color: rgba(255, 255, 255, 0.8);
    animation: rotate alternate 4s infinite;
    filter: blur(2px);
    animation-delay: 6.5s;
  }

  .scroll-about::after {
    content: "";
    position: absolute;
    inset: 3px;
    background-color: #000000;
    border-radius: 15px;
  }

  .section-name h2 {
    font-size: 8.5vw;
    transform: translateY(0px);
    width: 100%;
    margin: 0 15px;
  }

  .section-name:hover h2 {
    transform: translateY(0px);
  }

  section span {
    color: #e5e5e5;
    font-size: 30px;
  }

  .bold {
    color: #b4b4b4;
  }

  .italic {
    font-style: italic;
  }

  .colorDot,
  .smallDot,
  .tinyDot {
    transform: translate(2px, 3px);
    height: 10px;
    width: 10px;
  }

  .intro {
    display: flex;
    flex-direction: column;
  }

  #logo {
    width: 90vw;
    transform: translateY(-30px);
  }

  .logo {
    flex-direction: column;
  }

  #border {
    display: none;
  }

  .text {
    display: none;
  }

  .inprogress {
    margin-left: 15px;
    padding: 4px 6px;
    background-color: #171717;
    border-radius: 5px;
  }

  span.inprogress {
    font-size: 16px;
  }
}
@media (max-width: 330px) and (orientation: portrait) {
  .section-name h2 {
    font-size: 8vw;
  }
  .sayHi {
    display: none;
  }

  @keyframes show-up {
    to {
      bottom: 120px;
    }
  }
}

@media (width: 1920px) and (height: 1080px) {
  .light {
    display: block;
  }
}

@media (min-height: 1000px) and (min-width: 760px) and (orientation: portrait) {
  .name {
    height: 80px;
  }
}
</style>
