<template>
   <section id="technologies" class="tech-section">
      <div class="section-name">
         <h2>technologies<span class="colorDot"></span></h2>
      </div>
      <div class="technologies">
         <div class="tech">
            <span class="tech-name">html5</span>
            <img src="../assets/images/techs/html.svg" alt="html" />
         </div>
         <div class="tech">
            <span class="tech-name">css3</span>
            <img src="../assets/images/techs/css.svg" alt="css" />
         </div>
         <div class="tech">
            <span class="tech-name">javascript</span>
            <img src="../assets/images/techs/js.svg" alt="html" />
         </div>
         <div class="tech">
            <span class="tech-name">java</span>
            <img src="../assets/images/techs/java.svg" alt="html" />
         </div>
         <div class="tech">
            <span class="tech-name">docker</span>
            <img src="../assets/images/techs/docker.svg" alt="docker" />
         </div>
         <div class="tech">
            <span class="tech-name">hibernate</span>
            <img src="../assets/images/techs/hibernate.svg" alt="hibernate" />
         </div>
         <div class="tech">
            <span class="tech-name">spring</span>
            <img src="../assets/images/techs/spring.svg" alt="spring" />
         </div>
         <div class="tech">
            <span class="tech-name">mysql</span>
            <img src="../assets/images/techs/sql.svg" alt="sql" />
         </div>
         <div class="tech">
            <span class="tech-name">thymeleaf</span>
            <img id="thymeleaf" src="../assets/images/thymeleaf.png" alt="logo thymeleaf" />
         </div>
         <div class="tech">
            <span class="tech-name">gsap</span>
            <img src="../assets/images/techs/gsap.svg" alt="gsap" style="background-color: greenyellow" />
         </div>
         <div class="tech">
            <span class="tech-name">figma</span>
            <img src="../assets/images/techs/figma.svg" alt="figma" />
         </div>
         <div class="tech">
            <span class="tech-name">gimp</span>
            <img src="../assets/images/techs/gimp.svg" alt="gimp" />
         </div>
         <div class="tech">
            <span class="tech-name">Vue.js</span>
            <img src="../assets/images/techs/vue.svg" alt="vue.js" />
         </div>
         <div class="tech">
            <span class="tech-name">C#</span>
            <img src="../assets/images/techs/csharp.svg" alt="c#" />
         </div>
      </div>
   </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
   name: "TechComp",
   mounted: function () {
      this.scrollAnimation();
   },
   methods: {
      scrollAnimation() {
         const gs = gsap.timeline({
            scrollTrigger: {
               trigger: ".tech",
               start: "400% bottom",
            },
         });
         gs.fromTo(
            ".tech-section",
            { opacity: 0 },
            {
               opacity: 1,
               duration: 1.2,
            }
         );
         return () => {
            // gs.scrollTrigger.kill();
         };
      },
   },
};
</script>

<style>
.technologies {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.tech {
   position: relative;
   display: flex;
   align-items: center;
   border-radius: 10px;
   width: 49.5%;
   height: 60px;
   overflow: hidden;
   margin: 5px 0;
}

.tech svg,
.tech img {
   transform: translateY(-10%);
   height: 150%;
   position: absolute;
   right: -100px;
}

.tech img {
   right: 0;
   transform: translate(-25px, 0px);
}

.tech-name {
   font-size: 48px;
   text-transform: uppercase;
   font-weight: bold;
   cursor: pointer;
   line-height: 80%;
}

.tech:hover svg {
   opacity: 1;
}

.tech:hover .tech-name {
   animation: rotate360 0.5s linear forwards;
}
.tech:hover.tech::after {
   animation: shiny 1.5s linear;
}

.tech::after {
   content: "";
   position: absolute;
   left: -100%;
   width: 100%;
   height: 200%;
   background-color: rgba(255, 255, 255, 0.1);
   transform: rotateZ(140deg);
   filter: blur(30px);
}

.tech::before {
   content: "";
   position: absolute;
   left: 35%;
   width: 100%;
   height: 300%;
   background-color: rgba(255, 255, 255, 0.08);
   transform: rotateZ(140deg);
}

.tech:nth-of-type(odd) .tech-name {
   color: #fd5e5e;
}
@media (max-width: 1100px) and (orientation: portrait) {
   .technologies {
      flex-direction: column;
      align-items: center;
   }

   .tech {
      width: 90%;
   }

   .tech {
      height: 50px;
   }

   .tech span {
      font-size: 8vw;
   }

   .tech svg {
      height: 130%;
      right: 0;
   }

   .tech img {
      height: 130%;
      right: 0;
   }

   .tech svg,
   .tech img {
      transform: translateY(-10%);
      height: 150%;
      position: absolute;
      right: -130px;
   }
   .tech:nth-child(12) img {
      transform: translateY(5%);
   }
   .tech:last-child img {
      transform: translateY(10%);
   }
   .tech:nth-child(10) img {
      transform: translateY(5%);
   }

   .tech img {
      right: 0;
   }
   .tech:hover svg {
      opacity: 1;
   }

   .tech:hover .tech-name {
      animation: none;
   }
   .tech:hover.tech::after {
      animation: none;
   }
   .tech:hover .tech-name {
      animation: none;
   }
   .tech:hover.tech::after {
      animation: none;
   }
}
@media (max-width: 1200px) and (orientation: landscape) {
   .technologies {
      flex-direction: column;
      align-items: center;
   }

   .technologies {
      flex-direction: column;
      align-items: center;
   }

   .tech {
      width: 90%;
   }

   .tech {
      height: 50px;
   }

   .tech span {
      font-size: 6vw;
   }

   .tech svg {
      height: 130%;
      right: 0;
   }

   .tech img {
      height: 130%;
      right: 0;
   }

   .tech svg,
   .tech img {
      transform: translateY(-10%);
      height: 150%;
      position: absolute;
      right: -130px;
   }

   .tech img {
      right: 0;
   }
}
@media (max-width: 330px) and (orientation: portrait) {
   .tech img {
      height: 100%;
      right: -10px;
   }
   span.inprogress {
      font-size: 7px;
      margin-left: 3px;
   }
}
</style>
