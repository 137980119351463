<template>
  <div class="sayHi">
    <span id="sayHi"></span>
  </div>
</template>
<script>
export default {
  name: "SayHi",
  data() {
    return {
      i: 0,
    };
  },
  mounted: function () {
    setTimeout(this.sayHi,5600);
    this.letterSpacing();
  },
  methods: {
    sayHi() {
      let text = "frontend developer";
      let sayHi5 = document.querySelector("#sayHi");
      if (this.i < text.length) {
        sayHi5.innerHTML += text.charAt(this.i);
        this.i++;
        setTimeout(this.sayHi, 75);
      }
    },
    letterSpacing() {
      window.addEventListener("scroll", function () {
        let value = window.scrollY;
        let sayHi5 = document.querySelector("#sayHi");
        if (window.screen.width > 1200) {
          sayHi5.style.letterSpacing = 15 + value * 0.16 + "px";
        }
      });
    },
  },
};
</script>
<style></style>
