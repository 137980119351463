<template>
   <section id="about" class="about">
      <div class="section-name">
         <h2 class="about-me">Hi<span class="colorDot"></span></h2>
      </div>
      <div class="text-about-me">
         <img class="code" src="../assets/images/pc.png" alt="laptop" /><span
            >I am a full-stack developer passionate about creating modern web applications. I specialize in .NET (C#) and Vue.js
            technologies. My programming journey started with Java and Spring Framework, and then evolved towards full-stack
            development. I have solid experience in building applications using Vue.js with TypeScript on the frontend and C# on
            the backend. I continuously expand my knowledge through courses and personal projects, which helps me stay up-to-date
            with the latest industry trends. I invite you to explore my portfolio, which showcases selected projects implemented
            using these technologies.
         </span>
      </div>
   </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
   name: "AboutComp",
   mounted: function () {
      this.scrollAnimation();
   },
   methods: {
      scrollAnimation() {
         const gs = gsap.timeline({
            scrollTrigger: {
               trigger: ".about",
               start: "50% bottom",
            },
         });
         gs.fromTo(
            ".about",
            { x: "-30rem", opacity: 0 },
            {
               x: 0,
               opacity: 1,
               ease: "back.out(1)",
               duration: 1,
            }
         ),
            gs.fromTo(
               ".code",
               { x: "20rem", opacity: 0 },
               {
                  x: 0,
                  opacity: 1,
                  ease: "back.out(1)",
                  duration: 1,
                  delay: -0.8,
               }
            );
         return () => {
            gs.scrollTrigger.kill();
         };
      },
   },
};
</script>
<style>
.about {
   border-top: none;
}
.code {
   margin-bottom: 10px;
   width: 50%;
   font-size: 10vw;
   float: right;
   shape-outside: polygon(15.53% 2.36%, 83.83% 2.25%, 84.16% 80.2%, 99.54% 107.1%, 49.3% 106.85%, -9.48% 114.04%, 15.35% 74.41%);
}
.text-about-me span {
   font-size: 26px;
}
@media (max-width: 1100px) and (orientation: portrait) {
   .text-about-me span {
      font-size: 18px;
   }

   .code {
      width: 50%;
   }
}

@media (max-width: 1200px) and (orientation: landscape) {
   .text-about-me {
      margin: 0 15px;
   }

   .text-about-me span {
      font-size: 18px;
   }

   .code {
      width: 50%;
   }
}
@media (max-width: 1200px) and (orientation: landscape) {
   .text-about-me {
      margin: 0 15px;
   }

   .text-about-me span {
      font-size: 18px;
   }

   .code {
      width: 50%;
   }
}
</style>
